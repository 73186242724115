<script setup>
import { ref } from "vue";
import { Head, Link } from "@inertiajs/vue3";
defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
});
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/vue";
import {
    Bars3Icon,
    MinusSmallIcon,
    PlusSmallIcon,
    XMarkIcon,
} from "@heroicons/vue/24/outline";
import {
    UserIcon,
    ArrowPathIcon,
    CheckIcon,
    UsersIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
    ChevronRightIcon,
    PuzzlePieceIcon,
    PaintBrushIcon,
BanknotesIcon,
CalendarDaysIcon,
SpeakerXMarkIcon,
CreditCardIcon,
} from "@heroicons/vue/20/solid";

import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import ApplicationMark from "@/Components/ApplicationMark.vue";
import ApplicationLogoWhite from "@/Components/ApplicationLogoWhite.vue";

const navigation = [
    { name: "FAQ", href: "#faq" },
    { name: "Why get a tutor?", href: "#why_get_a_tutor" },
    { name: "Why become a tutor?", href: "#why_become_a_tutor" },
];
//{ name: "About us", href: "#" },
const why_get_a_tutor = [
    {
        name: "Same course, same professors",
        description:
            "On Tutorie, you will find tutors who have taken the same course, with the same professors, as you. So you get relevant help.",
        icon: UsersIcon,
    },
    {
        name: "Personalized sessions, or group sessions",
        description:
            "Easily find a tutor who can help you in your preferred format. Whether it's a one-on-one session, or a group session, you'll find them on Tutorie.",
        icon: PaintBrushIcon,
    },
    {
        name: "Always look for the perfect match",
        description:
            "By learning your struggles, we can recommend the best tutor for you, who is most suited to help you in the classes you're behind on.",
        icon: PuzzlePieceIcon,
    },
];
const why_become_a_tutor = [
    {
        name: "Turn knowledge into income",
        description:
            "Easily earn some extra cash by helping students who are struggling in the classes you've already aced.",
        icon: BanknotesIcon,
    },
    {
        name: "On your own schedule",
        description:
            "At Tutorie, you set your own schedule. You can tutor as much, or as little, as you want. Simply set time slots when you're free to tutor someone, and tutees can book you.",
        icon: CalendarDaysIcon,
    },
    {
        name: "Over with the WhatsApp spam",
        description:
            "No more spamming WhatsApp groups to find students. On Tutorie, you'll be visible to students who need help in the classes you're good at.",
        icon: SpeakerXMarkIcon,
    },
    {
        name: "Easy payouts",
        description:
            "We partner with Stripe to provide safe and secure payments, and payouts. Every transaction is through Stripe, and payouts go straight to your bank account.",
        icon: CreditCardIcon,
    },
];

const tiers = [
    {
        name: "Hobby",
        id: "tier-hobby",
        href: "#",
        priceMonthly: "$19",
        description:
            "The perfect plan if you're just getting started with our product.",
        features: [
            "25 products",
            "Up to 10,000 subscribers",
            "Advanced analytics",
            "24-hour support response time",
        ],
        featured: false,
    },
    {
        name: "Enterprise",
        id: "tier-enterprise",
        href: "#",
        priceMonthly: "$49",
        description: "Dedicated support and infrastructure for your company.",
        features: [
            "Unlimited products",
            "Unlimited subscribers",
            "Advanced analytics",
            "Dedicated support representative",
            "Marketing automations",
            "Custom integrations",
        ],
        featured: true,
    },
];
const faqs = [
    {
        question: "To be honest no one really asked any questions yet...",
        answer: "So we don't have any answers yet. So enjoy programming humor.",
    },
    {
        question: "Why do programmers prefer using dark mode?",
        answer: "Because light attracts bugs.",
    },
    {
        question: "Why did the software developer go to therapy?",
        answer: "Because he had too many unresolved issues.",
    },
    {
        question: "What's a programmer's favorite olympic sport?",
        answer: "The 100 meter sprint."
    },
    {
        question: "Why did the software developer go broke?",
        answer: "Because he ran out of cache."
    },
    {
        question: "How do you comfort a JavaScript bug?",
        answer: "You console it."
    },
    {
        question: "Why did the container go to therapy?",
        answer: "Because it had too many levels of isolation."
    },
    {
        question: "What's a Kubernetes cluster's favorite free time activity?",
        answer: "Listening to pod-casts."
    }
    // More questions...
];
const footerNavigation = {
    solutions: [
        { name: "Marketing", href: "#" },
        { name: "Analytics", href: "#" },
        { name: "Commerce", href: "#" },
        { name: "Insights", href: "#" },
    ],
    support: [
        { name: "Pricing", href: "#" },
        { name: "Documentation", href: "#" },
        { name: "Guides", href: "#" },
        { name: "API Status", href: "#" },
    ],
    company: [
        { name: "About", href: "#" },
        { name: "Blog", href: "#" },
        { name: "Jobs", href: "#" },
        { name: "Press", href: "#" },
        { name: "Partners", href: "#" },
    ],
    legal: [
        { name: "Claim", href: "#" },
        { name: "Privacy", href: "#" },
        { name: "Terms", href: "#" },
    ],
};

const mobileMenuOpen = ref(false);
</script>

<template>
    <Head title="Landing" />
    <div class="bg-white">
        <!-- Header -->
        <header class="absolute inset-x-0 top-0 z-50">
            <nav
                class="flex items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <div class="flex lg:flex-1">
                    <a href="#" class="-m-1.5 p-1.5">
                        <span class="sr-only">Tutorie</span>
                        <ApplicationLogoWhite class="w-auto h-10" />
                    </a>
                </div>
                <div class="flex lg:hidden">
                    <button
                        type="button"
                        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                        @click="mobileMenuOpen = true"
                    >
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon class="w-6 h-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="hidden lg:flex lg:gap-x-12">
                    <a
                        v-for="item in navigation"
                        :key="item.name"
                        :href="item.href"
                        class="text-sm font-semibold leading-6 text-white"
                        >{{ item.name }}</a
                    >
                </div>
                <div
                    class="items-center hidden lg:flex lg:flex-1 lg:justify-end"
                >
                    <Link
                        v-if="$page.props.auth.user"
                        :href="route('dashboard')"
                        class="text-md font-semibold leading-6 text-gray-900 flex bg-white px-3.5 py-2 rounded-full"
                    >
                        <UserIcon
                            class="bg-gray-950/30 p-[0.1rem] rounded-full h-6 w-6 text-white mr-2"
                        ></UserIcon
                        >{{ $page.props.auth.user.name.split(" ")[0] }}</Link
                    >

                    <template v-else>
                        <Link
                            :href="route('login')"
                            class="font-semibold text-gray-50 hover:text-gray-200 focus:outline focus:outline-2 focus:rounded-sm focus:outline-blue-500"
                            >Log in &rarr;</Link
                        >

                        <Link
                            v-if="canRegister"
                            :href="route('register')"
                            class="font-semibold bg-white px-3.5 py-2.5 rounded-md text-gray-800 ms-4 hover:text-gray-900 focus:outline focus:outline-2 focus:rounded-sm focus:outline-blue-500"
                            >Register</Link
                        >
                    </template>
                </div>
            </nav>
            <Dialog
                as="div"
                class="lg:hidden"
                @close="mobileMenuOpen = false"
                :open="mobileMenuOpen"
            >
                <div class="fixed inset-0 z-50" />
                <DialogPanel
                    class="fixed inset-y-0 right-0 z-50 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                >
                    <div class="flex items-center justify-between">
                        <a href="#" class="-m-1.5 p-1.5">
                            <span class="sr-only">Tutorie</span>
                            <ApplicationLogo class="w-auto h-10" />
                        </a>
                        <button
                            type="button"
                            class="-m-2.5 rounded-md p-2.5 text-gray-700"
                            @click="mobileMenuOpen = false"
                        >
                            <span class="sr-only">Close menu</span>
                            <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div class="flow-root mt-6">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="py-6 space-y-2">
                                <a
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :href="item.href"
                                    class="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                                    >{{ item.name }}</a
                                >
                            </div>
                            <div class="py-6">
                                <a
                                :href="route('login')"
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >Log in</a
                                >
                                <a
                                :href="route('register')"
                                    class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >Register</a
                                >
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>

        <main>
            <!-- Hero section -->
            <div
                class="relative pb-16 overflow-hidden bg-gray-900 isolate pt-14 sm:pb-20"
            >
                <!-- src="blend=111827&sat=-100&exp=15&blend-mode=multiply" -->
                <img
                    src="/assets/img/couches.jpg"
                    alt=""
                    class="absolute inset-0 object-cover w-full h-full -z-10 mix-blend-multiply"
                />
                <div
                    class="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style="
                            clip-path: polygon(
                                74.1% 44.1%,
                                100% 61.6%,
                                97.5% 26.9%,
                                85.5% 0.1%,
                                80.7% 2%,
                                72.5% 32.5%,
                                60.2% 62.4%,
                                52.4% 68.1%,
                                47.5% 58.3%,
                                45.2% 34.5%,
                                27.5% 76.7%,
                                0.1% 64.9%,
                                17.9% 100%,
                                27.6% 76.8%,
                                76.1% 97.7%,
                                74.1% 44.1%
                            );
                        "
                    />
                </div>
                <div class="px-6 mx-auto max-w-7xl lg:px-8">
                    <div class="max-w-2xl py-32 mx-auto sm:py-48 lg:py-56">
                        <div class="hidden sm:mb-8 sm:flex sm:justify-center">
                            <div
                                class="relative flex items-center px-4 py-1 text-sm leading-6 text-gray-200 rounded-full gap-x-4 ring-1 ring-white/20 hover:ring-white/30"
                            >
                                <span class="font-semibold text-blue-400"
                                    >Launched!</span
                                >
                                <span
                                    class="w-px h-4 bg-white/20"
                                    aria-hidden="true"
                                />
                                <a :href="route('register')" class="flex items-center gap-x-1">
                                    <span
                                        class="absolute inset-0"
                                        aria-hidden="true"
                                    />
                                    Join the tutoring revolution
                                    <ChevronRightIcon
                                        class="w-5 h-5 -mr-2 text-gray-200"
                                        aria-hidden="true"
                                    />
                                </a>
                            </div>
                        </div>
                        <div class="text-center">
                            <h1
                                class="text-4xl font-medium tracking-tight text-white sm:text-6xl"
                            >
                                Tutorie helps your academics to 
                                <span class="font-bold text-blue-400"
                                    >bloom</span
                                >
                            </h1>
                            <p class="mt-6 text-lg leading-8 text-gray-300">
                                Easily tutor, or find a qualified tutor, from
                                your own university.
                            </p>
                            <div
                                class="flex items-center justify-center mt-10 gap-x-6"
                            >
                                <a
                                    :href="route('register')"
                                    class="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
                                    >Find a tutor</a
                                >
                                <a
                                    :href="route('register')"
                                    class="text-sm font-semibold leading-6 text-white"
                                    >Become a tutor
                                    <span aria-hidden="true">→</span></a
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style="
                            clip-path: polygon(
                                74.1% 44.1%,
                                100% 61.6%,
                                97.5% 26.9%,
                                85.5% 0.1%,
                                80.7% 2%,
                                72.5% 32.5%,
                                60.2% 62.4%,
                                52.4% 68.1%,
                                47.5% 58.3%,
                                45.2% 34.5%,
                                27.5% 76.7%,
                                0.1% 64.9%,
                                17.9% 100%,
                                27.6% 76.8%,
                                76.1% 97.7%,
                                74.1% 44.1%
                            );
                        "
                    />
                </div>
            </div>

            <div class="py-24 bg-white sm:py-32" id="why_get_a_tutor">
                <div class="px-6 mx-auto max-w-7xl lg:px-8">
                    <div class="max-w-2xl mx-auto lg:mx-0">
                        <h2
                            class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                        >
                            Why get a tutor?
                        </h2>
                        <p class="mt-6 text-lg leading-8 text-gray-600"></p>
                    </div>
                    <div
                        class="max-w-2xl mx-auto mt-16 sm:mt-20 lg:mt-24 lg:max-w-none"
                    >
                        <dl
                            class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"
                        >
                            <div
                                v-for="reason in why_get_a_tutor"
                                :key="reason.name"
                                class="flex flex-col"
                            >
                                <dt
                                    class="text-base font-semibold leading-7 text-gray-900"
                                >
                                    <div
                                        class="flex items-center justify-center w-10 h-10 mb-6 bg-blue-600 rounded-lg"
                                    >
                                        <component
                                            :is="reason.icon"
                                            class="w-6 h-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    {{ reason.name }}
                                </dt>
                                <dd
                                    class="flex flex-col flex-auto mt-1 text-base leading-7 text-gray-600"
                                >
                                    <p class="flex-auto">
                                        {{ reason.description }}
                                    </p>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div class="py-24 bg-gray-900 sm:py-32" id="why_become_a_tutor">
                <div class="px-6 mx-auto max-w-7xl lg:px-8">
                    <div class="max-w-2xl mx-auto lg:mx-0">
                        <p
                            class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl"
                        >
                            Why become a tutor on Tutorie?
                        </p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            We're always looking for the best tutors to help
                            students. Here's why you should become a tutor on
                            our platform.
                        </p>
                    </div>
                    <dl
                        class="grid max-w-2xl grid-cols-1 gap-8 mx-auto mt-16 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16"
                    >
                        <div
                            v-for="reason in why_become_a_tutor"
                            :key="reason.name"
                            class="relative pl-9"
                        >
                            <dt class="inline font-semibold text-white">
                                <component
                                    :is="reason.icon"
                                    class="absolute w-5 h-5 text-blue-500 left-1 top-1"
                                    aria-hidden="true"
                                />
                                {{ reason.name }}
                            </dt>
                            {{ " " }}
                            <dd class="inline">{{ reason.description }}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- FAQ section -->
            <div class="px-6 mx-auto mt-32 max-w-7xl sm:mt-56 lg:px-8">
                <div class="max-w-4xl mx-auto divide-y divide-gray-900/10">
                    <h2
                        class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
                    >
                        Frequently asked questions
                    </h2>
                    <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
                        <Disclosure
                            as="div"
                            v-for="faq in faqs"
                            :key="faq.question"
                            class="pt-6"
                            v-slot="{ open }"
                        >
                            <dt>
                                <DisclosureButton
                                    class="flex items-start justify-between w-full text-left text-gray-900"
                                >
                                    <span
                                        class="text-base font-semibold leading-7"
                                        >{{ faq.question }}</span
                                    >
                                    <span class="flex items-center ml-6 h-7">
                                        <PlusSmallIcon
                                            v-if="!open"
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                        />
                                        <MinusSmallIcon
                                            v-else
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" class="pr-12 mt-2">
                                <p class="text-base leading-7 text-gray-600">
                                    {{ faq.answer }}
                                </p>
                            </DisclosurePanel>
                        </Disclosure>
                    </dl>
                </div>
            </div>
        </main>

        <!-- Footer -->
        <footer
            class="mt-32 bg-gray-900 sm:mt-56"
            aria-labelledby="footer-heading"
        >
            <h2 id="footer-heading" class="sr-only">Footer</h2>
            <div class="px-6 py-16 mx-auto max-w-7xl sm:py-24 lg:px-8 lg:py-32">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                    <ApplicationLogoWhite class="w-auto h-12" />
                    <div
                    v-if="false"
                        class="grid grid-cols-2 gap-8 mt-16 xl:col-span-2 xl:mt-0"
                    >
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3
                                    class="text-sm font-semibold leading-6 text-white"
                                >
                                    Solutions
                                </h3>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li
                                        v-for="item in footerNavigation.solutions"
                                        :key="item.name"
                                    >
                                        <a
                                            :href="item.href"
                                            class="text-sm leading-6 text-gray-300 hover:text-white"
                                            >{{ item.name }}</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-10 md:mt-0">
                                <h3
                                    class="text-sm font-semibold leading-6 text-white"
                                >
                                    Support
                                </h3>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li
                                        v-for="item in footerNavigation.support"
                                        :key="item.name"
                                    >
                                        <a
                                            :href="item.href"
                                            class="text-sm leading-6 text-gray-300 hover:text-white"
                                            >{{ item.name }}</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3
                                    class="text-sm font-semibold leading-6 text-white"
                                >
                                    Company
                                </h3>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li
                                        v-for="item in footerNavigation.company"
                                        :key="item.name"
                                    >
                                        <a
                                            :href="item.href"
                                            class="text-sm leading-6 text-gray-300 hover:text-white"
                                            >{{ item.name }}</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-10 md:mt-0">
                                <h3
                                    class="text-sm font-semibold leading-6 text-white"
                                >
                                    Legal
                                </h3>
                                <ul role="list" class="mt-6 space-y-4">
                                    <li
                                        v-for="item in footerNavigation.legal"
                                        :key="item.name"
                                    >
                                        <a
                                            :href="item.href"
                                            class="text-sm leading-6 text-gray-300 hover:text-white"
                                            >{{ item.name }}</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="px-6 py-12 pb-20 mx-auto border-t border-gray-600 max-w-7xl md:flex md:items-center md:justify-between lg:px-8"
            >
                <div class="mt-8 md:order-1 md:mt-0">
                    <p class="text-xs leading-5 text-center text-gray-500">
                        &copy; {{ new Date().getFullYear() }} Tutorie SL. All
                        rights reserved.
                    </p>
                </div>
                <div class="mt-8 md:order-1 md:mt-0">
                    <p class="text-xs leading-5 text-center text-gray-500">
                        Made with ❤️, and an unhealthy amount of Monster Energy
                    </p>
                </div>
            </div>
        </footer>
    </div>
</template>
